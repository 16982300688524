<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">API請求紀錄</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center">
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })">
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" />
                列印
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })">
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" />
                匯出 CSV
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })">
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出
                HTML
              </span>
            </div>
          </div>
        </div>
        <button v-show="grid.canCreateRow && !grid.isEditing"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center" @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <DateRange triggerType="immediate" dateType="c" :startDate="gridQueryDate?.startDate"
        :endDate="gridQueryDate?.endDate" @query="onQuery" />
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <!-- <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div> -->
        <!-- <div class="flex mt-2 sm:mt-0">
          <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button>
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid ref="grid" v-bind="gridOptions" @edit="onGridEdit" @removeSelectedRows="onGridRemoveSelectedRows">
          <template #modal="{ row }">
            <vxe-form class="ml-3" v-bind="formOptions" :data="row"> </vxe-form>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
import CloudFun, {
  Condition,
  defineComponent,
  Operator,
  ref,
  Sorting,
  SortOrder
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import formatDate from "xe-utils/toDateString";
import DateRange from "@/components/date-select/Range.vue";

export default defineComponent({
  components: {
    Grid,
    DateRange
  },
  setup() {
    const model = CloudFun.current?.model;
    const enums = CloudFun.current?.enums as any;
    const grid = ref<any>({});
    var printColumns = [{ field: "Name" }, { field: "Description" }];

    const startDate = new Date();
    startDate.setDate(new Date().getDate() - 6);
    const endDate = new Date();
    startDate.setHours(23, 59, 59, 0);
    const gridQueryDate = ref<{
      startDate: Date;
      endDate: Date;
    } | null>({
      startDate,
      endDate,
    });
    const gridOptions: GridOptions = {
      title: "API請求紀錄",
      canCreate: false,
      canUpdate: false,
      canDelete: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,
      printConfig: {
        sheetName: "API請求紀錄",
        columns: printColumns,
        modes: ["current", "selected", "all"]
      },
      exportConfig: {
        filename: "API請求紀錄",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns
      },
      columns: [
        {
          field: "CreatedTime",
          title: "時間",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) =>
            formatDate(new Date(cellValue), "yyyy/MM/dd HH:mm:ss")
        },
        {
          field: "Type",
          title: "類型",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) =>
            (Object.values(enums.ApiType) as any[]).find(
              e => e.Value === cellValue
            )?.Name
        }
      ],
      promises: {
        query: model
          ? params =>
            new Promise(resolve => {
              if (gridQueryDate.value) {
                params.condition = new Condition("CreatedTime", Operator.GreaterThanOrEqual, gridQueryDate.value.startDate)
                  .and("CreatedTime", Operator.LessThan, gridQueryDate.value.endDate).and(params.condition!);

              }
              params.sortings?.push(
                new Sorting("CreatedTime", SortOrder.Descending)
              );
              model.dispatch("apiLog/query", params).then(payload => {
                resolve(payload);
              });
            })
          : undefined,
        queryAll: model ? () => model.dispatch("apiLog/query") : undefined
      }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: "right",
      items: [
        {
          field: "StartTime",
          title: "時間",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "datetime", readonly: true }
          }
        },
        {
          field: "EndTime",
          title: "時間",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "datetime", readonly: true }
          }
        },
        {
          field: "IvarId",
          title: "人流計數主機",
          titleWidth: "120",
          span: 24,
          itemRender: { name: "$input", props: { readonly: true } }
        },
        // {
        //   field: "ChannelId",
        //   title: "攝影機",
        //   span: 12,
        //   itemRender: { name: "$input", props: { readonly: true } }
        // },
        {
          field: "Content",
          titleWidth: "120",
          title: "回傳資料內容",
          span: 24,
          itemRender: { name: "$textarea", props: { readonly: true } }
        }
      ]
    };

    const onQuery = (query: any) => {
      gridQueryDate.value = query;
      grid.value.refresh();
    }

    return {
      grid,
      gridOptions,
      formOptions,
      gridQueryDate,
      onQuery
    };
  },
  methods: {
    onGridRemoveSelectedRows(rows: any, callback: any) {
      cash("#batch-dropdown").dropdown("hide");
      callback();
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id
        ? await this.$model.dispatch("apiLog/find", row.Id)
        : undefined;
      if (entity) {
        Object.assign(row, entity);
      }
      callback();
    }
  }
});
</script>
