
import CloudFun, {
  Condition,
  defineComponent,
  Operator,
  ref,
  Sorting,
  SortOrder
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import formatDate from "xe-utils/toDateString";
import DateRange from "@/components/date-select/Range.vue";

export default defineComponent({
  components: {
    Grid,
    DateRange
  },
  setup() {
    const model = CloudFun.current?.model;
    const enums = CloudFun.current?.enums as any;
    const grid = ref<any>({});
    var printColumns = [{ field: "Name" }, { field: "Description" }];

    const startDate = new Date();
    startDate.setDate(new Date().getDate() - 6);
    const endDate = new Date();
    startDate.setHours(23, 59, 59, 0);
    const gridQueryDate = ref<{
      startDate: Date;
      endDate: Date;
    } | null>({
      startDate,
      endDate,
    });
    const gridOptions: GridOptions = {
      title: "API請求紀錄",
      canCreate: false,
      canUpdate: false,
      canDelete: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,
      printConfig: {
        sheetName: "API請求紀錄",
        columns: printColumns,
        modes: ["current", "selected", "all"]
      },
      exportConfig: {
        filename: "API請求紀錄",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns
      },
      columns: [
        {
          field: "CreatedTime",
          title: "時間",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) =>
            formatDate(new Date(cellValue), "yyyy/MM/dd HH:mm:ss")
        },
        {
          field: "Type",
          title: "類型",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) =>
            (Object.values(enums.ApiType) as any[]).find(
              e => e.Value === cellValue
            )?.Name
        }
      ],
      promises: {
        query: model
          ? params =>
            new Promise(resolve => {
              if (gridQueryDate.value) {
                params.condition = new Condition("CreatedTime", Operator.GreaterThanOrEqual, gridQueryDate.value.startDate)
                  .and("CreatedTime", Operator.LessThan, gridQueryDate.value.endDate).and(params.condition!);

              }
              params.sortings?.push(
                new Sorting("CreatedTime", SortOrder.Descending)
              );
              model.dispatch("apiLog/query", params).then(payload => {
                resolve(payload);
              });
            })
          : undefined,
        queryAll: model ? () => model.dispatch("apiLog/query") : undefined
      }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: "right",
      items: [
        {
          field: "StartTime",
          title: "時間",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "datetime", readonly: true }
          }
        },
        {
          field: "EndTime",
          title: "時間",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "datetime", readonly: true }
          }
        },
        {
          field: "IvarId",
          title: "人流計數主機",
          titleWidth: "120",
          span: 24,
          itemRender: { name: "$input", props: { readonly: true } }
        },
        // {
        //   field: "ChannelId",
        //   title: "攝影機",
        //   span: 12,
        //   itemRender: { name: "$input", props: { readonly: true } }
        // },
        {
          field: "Content",
          titleWidth: "120",
          title: "回傳資料內容",
          span: 24,
          itemRender: { name: "$textarea", props: { readonly: true } }
        }
      ]
    };

    const onQuery = (query: any) => {
      gridQueryDate.value = query;
      grid.value.refresh();
    }

    return {
      grid,
      gridOptions,
      formOptions,
      gridQueryDate,
      onQuery
    };
  },
  methods: {
    onGridRemoveSelectedRows(rows: any, callback: any) {
      cash("#batch-dropdown").dropdown("hide");
      callback();
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id
        ? await this.$model.dispatch("apiLog/find", row.Id)
        : undefined;
      if (entity) {
        Object.assign(row, entity);
      }
      callback();
    }
  }
});
